/* src/fonts.css */
@font-face {
    font-family: 'Enoway';
    src: url('./EnowayPersonalUse.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Avenir';
    src: url('./AvenirRegular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  .heading {
    font-family: 'Enoway';
  }
  