.container-cropper {
    height: 90%;
    padding: 1%;
    /* width: 500px;
    height: 1000px;
    z-index: 10; */
    z-index: 10;
}

.cropper {
    position: relative;
    width: 250px;
    height: 250px;
    z-index: 10;
}

.slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;
    z-index: 1;
}

.container-buttons {
    /* border: 1px solid #f5f5f5; */
    height: 10%;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    z-index: 10;
}

.no-scroll {
    overflow: hidden !important;
  }
  
  .ImageBoxx {
    /* width: 100%; */
    height: 50px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 50ms;
}


@media (max-width:420px) {
  .ImageBoxx{
      height: 100px;
  }
}